<template>
  <!--<PageHeader :title="title" :items="items" />-->
  <div class="row">
    <div class="col-12">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <div class="card">
          <div class="col-12 mt-2">
            <div class="card-header cardHeader-bg" style="padding: 14px;">
              <router-link to="/orders">
                <button class="btn btn-info rounded-pill mb-2">
                  Back
                </button>
              </router-link>
              <h4 class="card-title mb-0">{{ headerTitle }}</h4>
              <br />
              <div
                v-if="alert"
                role="alert"
                aria-live="polite"
                aria-atomic="true"
                class="alert alert-danger"
              >
                {{ alertText }}
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <b-form-group
                id="input-group-3"
                label="Restaurant *"
                label-for="input-3"
              >
                <multiselect
                  track-by="id"
                  :custom-label="customLabel"
                  label="username"
                  v-model="selected"
                  :options="users"
                  placeholder="Select Restaurant"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.name }} - ({{
                        option.current_address
                      }})</strong
                    ></template
                  >
                </multiselect>
              </b-form-group>
              <b-form-group label="First Name *">
                <b-form-input
                  id="input-2"
                  v-model="f_name"
                  required
                  placeholder="Enter First Name"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Last  Name *">
                <b-form-input
                  id="input-2"
                  v-model="l_name"
                  required
                  placeholder="Enter Last Name"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Email *">
                <b-form-input
                  id="input-2"
                  v-model="email"
                  required
                  placeholder="Enter Email"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Phone Number *"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="phone_number"
                  type="text"
                  required
                  placeholder="Enter Phone Number"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-3"
                label="Profile Updation Status *"
                label-for="input-3"
              >
                <b-form-select
                  id="input-3"
                  v-model="profileUpdation"
                  :options="profileUpdations"
                  required
                ></b-form-select>
              </b-form-group>
              <b-form-group
                class="mr-3"
                id="input-group-2"
                label="Password * "
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="password"
                  type="password"
                  placeholder="Enter Password"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Profile Image:"
                label-for="input-2"
              >
                <input type="file" @change="profileImageShow" /><br /><br />
                <img
                  style="width: 140px;
    height: 140px;
    border-radius: 16px;"
                  v-if="profileimageUrl"
                  :src="profileimageUrl"
                />
              </b-form-group>
              <b-button ref="save" type="submit" class="btn-info"
                >Save</b-button
              >
            </div>
          </div>
        </div>
        <br />

        <br />

        <template v-if="isShowModel">
          <VerficationModal
            :show="showModal"
            :userObj="userObj"
            :actionType="actionType"
            @deleteImageRecord="deleteImageRecord"
            @deleteMeunItem="deleteMeunItem"
            @closeModal="closeModal"
          />
        </template>
      </b-form>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
// import { appendRow, deleteRow } from "@/Helper/helpers.js";
import Vue from "vue";
import VerficationModal from "./VerficationModal";
import Multiselect from "vue-multiselect";
import axios from "axios";
export default {
  components: {
    Multiselect,
    VerficationModal,
  },
  data() {
    return {
      deliveryAddresses: [],
      meunItemsTempArray: [],
      deliveryAddress: null,
      minimunOrderAmout: null,
      profileUpdation: null,
      profileUpdations: [
        { text: "Select Profile Status", value: null },
        "Pending",
        "Approved",
      ],
      deliveryCost: null,
      customers: [],
      delivery_time: "",
      street_address: "",
      type: "1",
      customerId: null,
      headerTitle: "Add Rider",
      meunItems: [],
      apiResponceError: false,
      totalMenuItemsPrice: 0.0,
      meunItemsIds: [],
      totalPrice: 0.0,
      changeClasss: false,
      showDeliveryTimeInput: false,
      showInputForDelivery: true,
      currentAddressname: "",
      restaurant_slug: "",
      email: "",
      f_name: "",
      profileimageUrl: null,
      l_name: "",
      size: null,
      id: "",
      selected: null,
      user: null,
      users: [],
      imagesShow: false,
      showDeleteModal: false,
      showModal: false,
      imagesArray: [],
      loans: [],
      isShowModel: false,
      alertText: "",
      alert: false,
      showImagesViewTable: false,
      deliveryAddressView: false,
      orderDeliveryAddress: {},
      phone_number: "",
      password: "",
      show: true,
      apiUrl: "/rider/register",
    };
  },

  mounted() {
    document.title = "Add Rider";
    this.getAllRestaurant();
    if (this.$router.history.current.path == "/editrider") {
      this.headerTitle = "Edit Rider";
      this.apiUrl = "/rider/updateUser";
      document.title = "Edit Rider";

      this.editDataApppend();
    }
  },
  methods: {
    isEmailValid: function isEmailValid() {
      this.apiResponceError = false;
      var regex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
      var responce = regex.test(this.email);
      if (responce) {
        this.alert = false;
      } else {
        this.alertText = "Please enter valid email address";
        this.changeClasss = false;
        this.alert = true;
      }
      return responce;
    },

    profileImageShow(e) {
      const file = e.target.files[0];
      this.profileImage = file;
      this.profileimageUrl = URL.createObjectURL(file);
    },

    editDataApppend() {
      this.editorder = JSON.parse(localStorage.getItem("editrider"));
      if (this.editorder.profile_image) {
        this.profileimageUrl = this.editorder.profile_image;
        this.profileImage = this.editorder.profile_image;
      }
      this.f_name = this.editorder.f_name;
      this.l_name = this.editorder.l_name;
      this.email = this.editorder.email;
      this.phone_number = this.editorder.phone_number;
      this.profileUpdation = this.editorder.profile_updation;
      this.id = this.editorder.id;
      this.selected = this.editorder.restaurant;
    },

    deleteData(object, actioType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actioType;
    },

    appendRow() {
      this.deliveryAddressArray.push(this.deliveryAddresstempArray[0]);
      this.$refs.autocomplete.clear();
      this.toasterMessage();
      if (this.deliveryAddressArray.length > 0) {
        this.deliveryAddressView = true;
      }
    },

    toasterMessage() {
      Vue.$toast.success("Delivery Address Save Please Check Blow", {
        position: "top-right",
        duration: 5000,
      });
    },

    closeModal(value) {
      this.isShowModel = value;
    },

    showBulkPopupDeliveryAddress(object, actionType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actionType;
    },

    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },

    customLabel({ name, current_address }) {
      return `${name}—(${current_address})`;
    },

    validationMessage(message) {
      this.alert = true;
      this.alertText = message;
      this.changeClasss = false;
    },
    onSubmit(evt) {
      try {
        const formData = new FormData();
        evt.preventDefault();
        if (this.password.length > 0 && this.password.length < 6) {
          this.validationMessage("The Password must be at least 6 characters.");
          return false;
        } else {
          this.alert = false;
        }

        if (this.phone_number.length > 0 && this.phone_number.length < 11) {
          this.validationMessage(
            "The phone number must be at least 11 characters."
          );
          return false;
        } else {
          this.alert = false;
        }
        if (this.alert) {
          evt.preventDefault();
          return false;
        }

        formData.append("id", this.id);
        this.addLoaderClasses("save");
        formData.append("f_name", this.f_name);
        formData.append("l_name", this.l_name);
        formData.append("email", this.email);
        formData.append("restaurant_id", this.selected.id);
        formData.append("phone_number", this.phone_number);
        formData.append("profile_updation", this.profileUpdation);
        formData.append("password", this.password);
        formData.append("platform", "adminpanel");
        if (this.profileImage) {
          formData.append("profile_image", this.profileImage);
        }

        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + this.apiUrl, formData, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              let message = "";
              if (this.id != "") {
                message = "Record has been updated successfully";
              } else {
                message = "Record has been added successfully";
              }
              localStorage.setItem("alertmessage", JSON.stringify(message));
              this.$router.push({ name: "riders" });
            } else {
              this.alert = true;
              this.apiResponceError = true;
              this.alertText = response.data.message;
              this.removeLoaderClasses("save");
            }

            if (response.data.code == 501) {
              this.$router.push({ name: "login" });
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    addLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    removeLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },

    getLogo(logo) {
      return this.$IMAGE_BASE_URL + logo;
    },

    getAllRestaurant() {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/user/restaurant/list", "", {
            headers,
          })
          .then((response) => {
            this.users = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.multiselect__single {
  font-size: 12px;
}
.multiselect * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}

.multiselect__option--highlight:after {
  background-color: #428bca !important;
}

.alert {
  padding: 0.3rem 0.5rem;
}
.btn-info {
  background-color: #5cb700;
  border-color: #5cb700;
}
.btn-info:hover {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:active {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:focus {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}
</style>
